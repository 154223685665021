require("pickadate/lib/compressed/picker");
require("pickadate/lib/compressed/picker.date");
require("jquery-validation/dist/jquery.validate.min");
require("jquery.payment/lib/jquery.payment.min");
require("lodash");
if (!window.hideChat) {
  require("js/zopim.min");
}
require("waypoints/lib/jquery.waypoints.min");
require("es6-promise/dist/es6-promise.auto.min");
